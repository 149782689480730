<template>
   <div class="ident">
       <div class="toptit">
           <p><span>企业认证</span>进行企业认证后，您在本平台进行投保或批改时，投保或批改资料无需再进行线下盖章。</p>
       </div>
       <div class="message">
           <div class="flow">
               <div>
                   <img src="../../image/signet/flow1.png" alt="">
                   <p>企业信息认证</p>
               </div>
               <span class="activate"></span>
               <div>
                    <img src="../../image/signet/flow2.png" alt="">
                    <p>申请电子签章</p>
               </div>
               <span></span>
               <div>
                    <img src="../../image/signet/flow3.png" alt="">
                    <p>生成电子签章</p>
               </div>
           </div>
           <div class="tab">
               <p :class="staff==1?'activate':''" @click="staffbtn(1)">我是法人</p>
               <p :class="staff==2?'activate':''" @click="staffbtn(2)">我是企业代理人</p>
           </div>
           <div class="box">
               <p class="tit">企业基础信息</p>
               <div class="con">
                   <div class="item" v-if="show">
                       <label><span>*</span>上传授权书</label>
                       <div>
                           <div class="up">
                               <el-upload
                                v-if="!impowerurl"
                                class="impower"
                                drag
                                :action="baseUrl+'/api/order/orders/uploadCard'"
                                :data="uploaddata"
                                :on-success="impower"
                                :multiple='false'
                                :limit="1"
                                accept='.png,.jpeg,.jpg'
                                :show-file-list="true">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text"><em>将授权书拖到此处，<br>或点击上传</em></div>
                                </el-upload>
                                <div v-else class="imagtt">
                                    <el-image 
                                        :src="baseUrl + impowerurl" 
                                        :preview-src-list="[baseUrl + impowerurl]">
                                    </el-image>
                                    <i class="el-icon-circle-close"  @click="deleteimg(1)"></i>
                                </div>
                                <div class="right">
                                    <p ><span @click="openpop(1)">查看示例</span><a href="/file/authorization_template.docx" target="_blank" download="授权书">下载授权书</a></p>
                                    <p>请上传png,jpeg,jpg格式图片，为避免识别失败，请上传清晰原件正面扫描件，拍照上传时照片需正面端正、无反光；复印件盖章请勿遮挡关键信息。</p>
                                </div>
                           </div>
                       </div>
                   </div>
                   <div class="item">
                       <label><span>*</span>上传营业执照</label>
                       <div>
                           <div class="up">
                               <el-upload
                                v-if="!businessurl"
                                class="business"
                                drag
                                :action="baseUrl+'/api/order/orders/uploadCard'"
                                :data="uploaddata"
                                :on-success="business"
                                :multiple='false'
                                :limit="1"
                                accept='.png,.jpeg,.jpg'
                                :show-file-list="true">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text"><em>将营业执照拖到此处，<br>或点击上传</em></div>
                                </el-upload>
                                <div v-else class="imagtt">
                                    <el-image 
                                        :src="baseUrl + businessurl" 
                                        :preview-src-list="[baseUrl + businessurl]">
                                    </el-image>
                                    <i class="el-icon-circle-close" @click="deleteimg(2)"></i>
                                </div>
                                <div class="right">
                                    <p><span @click="openpop(2)">查看示例</span></p>
                                    <p>请上传png,jpeg,jpg格式图片，为避免识别失败，请上传清晰原件正面扫描件，拍照上传时照片需正面端正、无反光；复印件盖章请勿遮挡关键信息。</p>
                                </div>
                           </div>
                           <p><i class="el-icon-warning"></i>以下信息自动识别，需仔细核对，如识别有误，请修正。</p>
                       </div>
                   </div>
                   <div class="item">
                       <label><span>*</span>企业全称</label>
                       <div>
                           <input type="text" v-model="company">
                       </div>
                   </div>
                   <div class="item">
                       <label><span>*</span>统一社会信用代码</label>
                       <div>
                           <input type="text" v-model="coding" @change="creditif()">
                       </div>
                   </div>
                   <div class="item">
                       <label><span>*</span>法人代表姓名</label>
                       <div>
                           <input type="text" v-model="name">
                       </div>
                   </div>
               </div>
                <p class="tit" v-if="show">企业代理人信息</p>
                <p class="tit" v-else>法人信息</p>
                <div class="con">
                    <div class="item">
                        <label v-if="show"><span>*</span>上传代理人身份证照片</label>
                        <label v-else><span>*</span>上传法人身份证照片</label>
                        <div>
                            <div class="up">
                                <el-upload
                                v-if="!identityurl"
                                class="picture"
                                drag
                                :action="baseUrl+'/api/order/orders/uploadCard'"
                                :data="uploaddata1"
                                :on-success="picture"
                                 :multiple='false'
                                :limit="1"
                                accept='.png,.jpeg,.jpg'
                                :show-file-list="true">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text"><em>将身份证正面拖到此处，<br>或点击上传</em></div>
                                </el-upload>                                
                                <div v-else class="imagtt">
                                    <el-image 
                                        :src="baseUrl + identityurl" 
                                        :preview-src-list="[baseUrl + identityurl]">
                                    </el-image>
                                    <i class="el-icon-circle-close" @click="deleteimg(3)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="tit bto">核实证件信息：</p>
                <div class="con">
                    <div class="item">
                        <label><span>*</span>姓名</label>
                        <div>
                            <input type="text" v-model="zname" @change="altertext(1)">
                        </div>
                    </div>
                    <div class="item">
                        <label><span>*</span>身份证号</label>
                        <div>
                            <input type="text" v-model="zid"  @change="idif();altertext(2)">
                        </div>
                    </div>
                    <div class="item">
                        <label><span>*</span>手机号</label>
                        <div class="phtis">
                            <input type="text" v-model="zphone"  @change="phoneif(1)">
                            <p>填写的手机号，必须是持<span  v-if="show">代理人</span><span v-else>法人</span>身份证购买的手机号。</p>
                        </div>
                    </div>
                    <div class="item">
                        <label><span>*</span> 验证码</label>
                        <div class="verify">
                            <input type="text" v-model="verifytext" @change="changeveri()">
                            <p @click="chufa?verifyshij():''">{{verifybtn}}</p>
                        </div>
                    </div>
                </div>
                <p class="tit">企业联系人信息</p>
                <div class="con">
                    <div class="item">
                        <label><span>*</span>联系人姓名</label>
                        <div>
                            <input type="text" v-model="lxname">
                        </div>
                    </div>
                    <div class="item">
                        <label><span>*</span>联系人手机号</label>
                        <div>
                            <input type="text" v-model="lxphone"  @change="phoneif(2)">
                        </div>
                    </div>
                    <div class="item">
                        <label><span>*</span>联系人邮箱</label>
                        <div>
                            <input type="email" v-model="email" @change="checkEmail()">
                        </div>
                    </div>
                    <div class="item tis">
                        <label>温馨提示：</label>
                        <div>
                            <p>因后期签署合同将向此手机号发送短信验证码，请填写真实手机号。</p>
                        </div>
                    </div>
                </div>
                <div class="btnbox">
                    <el-button type="primary" @click="submit()">提交认证</el-button>
                </div>
           </div>
       </div>
       <el-dialog
            :title="shilVitit"
            :visible.sync="shilVi"
            width="30%"
            center
            custom-class='dialogshil'>
            <div class="shilbox" v-if='shil==1'>
                <p>为保证企业在后续的保障利益中不受损害，非企业法人本人创建时，代理人须获得企业书面授权，代理人下载授权书加盖公章后在进行上传。</p>
                <img src="../../image/signet/sqc.png" alt="">
            </div>
            <div class="shilbox" v-if='shil==2'>
                <p>针对企业信息会进行工商实名验证，请上传企业真实营业执照，为避免识别有误，建议上传原件扫描</p>
                <img src="../../image/signet/yy.png" alt="">
            </div>
        </el-dialog>
        
   </div>
</template>
<script>
    
    export default {
        name: "ident",
       
        data() {
            return {
                baseUrl:this.$store.state.baseUrl,
                uid:'',
                show:false,//代理是否显示
                staff:1,// 1法人 2代理
                impowerurl:'',//授权文件
                businessurl:'',//营业执照文件
                identityurl:'',//法人身份文件
                company:'',//企业全称
                coding:'',//执照id
                name:'',//法人
                identity:'',//法人身份
                zname:'',//证件名字
                zid:'',//证件id
                zphone:'',//证件手机号
                verifytext:'',//验证吗
                verifybtn:'获取验证码',//企业全称
                time:60,//验证码时间
                chufa:true,//触发验证
                personalIdentity3Key:'',//验证返回
                pass:false,//记录验证码是否通过
                lxname:'',//联系人姓名
                lxphone:'',//联系人手机号
                email:'',//邮箱
                uploaddata:{
                     authCheck:0,
                    login_type:0,
                    type:0,
                },//上传附带参数
                uploaddata1:{
                    authCheck:0,
                    login_type:0,
                    type:1,
                    uid:'5555'
                },//上传附带参数
                shilVi:false,//示例弹窗
                shil:1,//示例
                shilVitit:'授权书示例',//示例标题 
            }
        },
        mounted() {
            this.uid=this.GetQueryString('uid')
        },
        methods: {
            staffbtn:function(num){
                console.log(num)
                this.staff=num
                if(num==1){
                    this.show=false
                }else{
                    this.show=true
                }
            },
            impower:function(e){
                console.log(e)
                this.impowerurl=e.data.url
            },
            business:function(e){
                console.log(e)
                this.businessurl=e.data.url
                if(e.data.result.errcode==0){
                    this.company=e.data.result.enterprise_name
                    this.coding=e.data.result.reg_num
                    this.name=e.data.result.legal_representative
                    this.$message.success('营业执照信息识别成功！如有问题可进行修改。');
                }else{
                    this.$message.error('营业执照信息识别失败，请手动进行填写。');
                }
            },
            picture:function(e){
                console.log(e)
                this.identityurl=e.data.url
                 if(e.data.result){
                    this.zname=e.data.result['姓名'].words
                    this.zid=e.data.result['公民身份号码'].words
                    this.$message.success('公民身份证信息识别成功！如有问题可进行修改。');
                }else{
                    this.$message.error('公民身份证信息识别失败！如有问题可进行修改。');
                }
               
            },
            deleteimg:function(num){
                if(num==1){
                   this.impowerurl="" 
                }else if(num==2){
                    this.businessurl="" 
                }else{
                    this.identityurl="" 
                }
            },
            openpop:function(e){
                this.shil=e
                if(e==1){
                    this.shilVitit="授权书示例"
                }else{
                    this.shilVitit="营业执照示例"
                }
                this.shilVi=true
            },
            // 点击获取验证码
            verifyshij:function(){
                var that=this      
                if(!this.coding){this.$message.error('统一社会信用代码不能为空');return}
                if(!this.zname){this.$message.error('姓名不能为空');return}
                if(!this.zid){this.$message.error('身份证号不能为空');return}
                if(!this.zphone){this.$message.error('手机号不能为空');return}          
                var _data={
                    uid:this.uid,
                    name:this.zname,
                    identity:this.zid,
                    regCode:this.coding,
                    mobile:this.zphone
                }
                that.chufa=false;
                that.axios({
                    url: this.baseUrl + '/api/authentication/authentication/getUserPhoneCode',
                    method: 'POST',
                    params:_data,
                }).then(function(res) {
                    console.log(res)
                    if(res.data.code==200){
                        that.personalIdentity3Key=res.data.data.personalIdentity3Key;
                        that.$message.success(res.data.msg);
                        let interval=setInterval(function() {
                            that.time=that.time - 1,
                            that.verifybtn="已发送("+that.time+"秒)"                            
                            if (that.time <= 0) {
                                clearInterval(interval)
                                that.time=60,
                                that.verifybtn="重新获取"
                                that.chufa=true;
                            }
                        }, 1000)
                    }else{
                        that.$message.error(res.data.msg);
                        that.chufa=true;
                    }

                }).catch(function (error) {
                    console.log(error);
                });                
            },
            // 验证验证码是否正确
            changeveri:function(){
                var that=this
                if(that.personalIdentity3Key){
                    if(that.verifytext){
                        that.axios({
                            url: this.baseUrl + '/api/authentication/authentication/checkUserPhoneCode',
                            method: 'POST',
                            params:{
                                uid:this.uid,
                                vcode:that.verifytext,
                                regCode:this.coding,
                                personalIdentity3Key:that.personalIdentity3Key
                            },
                        }).then(function(res) {
                            console.log(res)
                            if(res.data.code==200){
                                that.$message.success(res.data.msg);
                                that.pass=true
                            }else{
                                that.$message.error(res.data.msg);
                                that.pass=false
                            }
                           
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }
                }else{
                    this.$message.error('请先获取验证码');
                }
            },


            //提交认证
            submit:function(){
                var that=this;
                if(!this.company){this.$message.error('企业全称不能为空');return}
                if(!this.businessurl){this.$message.error('请上传营业执照');return}
                if(!this.coding){this.$message.error('统一社会信用代码不能为空');return}
                if(!this.name){this.$message.error('法人代表姓名不能为空');return}
                if(!this.identityurl){this.$message.error('请上传身份证照片');return}
                if(!this.zphone){this.$message.error('手机号不能为空');return}
                if(!this.zname){this.$message.error('姓名不能为空');return}
                if(!this.zid){this.$message.error('身份证号不能为空');return}
                if(!this.lxname){this.$message.error('联系人姓名不能为空');return}
                if(!this.lxphone){this.$message.error('联系人手机号不能为空');return}
                if(!this.email){this.$message.error('联系人邮箱不能为空');return}
                if(this.staff==2){
                    if(!this.impowerurl){this.$message.error('请上传授权书');return}
                }
                if(this.verifytext){
                    if(!this.pass){this.$message.error('验证码不正确');return}
                }else{
                    this.$message.error('请先获取验证码');return
                }
                var _data={
                    uid:this.uid,
                    name:this.company,
                    legalPersonName:this.name,
                    mobile:this.zphone,
                    regCode:this.coding,
                    legalPerson:this.zname,
                    legalPersonIdentity:this.zid,
                    contactsMobile:this.lxphone,
                    contactsName:this.lxname,
                    contactsEmail:this.email,
                    type:this.staff,
                    businessLicense:this.businessurl,
                    idCard:this.identityurl,
                    vcode:that.verifytext,
                    personalIdentity3Key:that.personalIdentity3Key
                }
                if(this.staff==2){_data.authorization=this.impowerurl}
                that.axios({
                    url: this.baseUrl + '/api/authentication/authentication/setCertificationInfo',
                    method: 'POST',
                    params:_data,
                }).then(function(res) {
                    console.log(res)
                    var msg=res.data.msg
                    if(res.data.code==200){
                        if(that.staff==1){
                            that.$router.push({
                                path:'apply',
                                query:{
                                    id:that.uid,
                                    name:that.company,
                                    regCode:res.data.data.regCode
                                }
                            })
                        }else{
                            that.$alert('<div class="conbox"><p><i class="el-icon-success" style="color:D82323"></i>企业信息提交成功！</p><p>审核专员将在3个工作小时内完成审核。<br>如有疑问请联系：400-616-2199</p></div>', '提交成功', {
                                dangerouslyUseHTMLString: true,
                                center:true,
                                customClass:"hintbox",
                                confirmButtonText:"好的，我知道了。"
                            }).then(() => { 
                                var userAgent = navigator.userAgent;
                                if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
                                    location.href = "about:blank";
                                } else {
                                    window.opener = null;
                                    window.open('', '_self');
                                }
                                window.close();
                            });

                        }
                        
                    }else{
                         that.$alert('<div class="conbox"><p><i class="el-icon-warning" style="color:#D82323"></i>抱歉，您的企业认证未通过，请更改信息重新提交。</p><p>未通过原因：<span>'+ msg +'</span></p></div>', '提交成功', {
                            dangerouslyUseHTMLString: true,
                            center:true,
                            customClass:"hintbox",
                            confirmButtonText:"好的，我知道了。"
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            creditif:function(){//验证营业执照 num=1投保人 2被保人
                if (!(/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(this.coding))) {
                    this.coding=""
                    this.$message.error('统一社会信用代码不正确');
                }
            },
            phoneif:function(num){//验证营业执照 num=1投保人 2被保人
                let phone=''
                if(num==1){
                    phone=this.zphone
                    this.personalIdentity3Key=''
                    if(this.pass){
                        this.pass=false
                        this.$message.error('手机号已修改请重新获取验证码')
                    }
                }else{
                    phone=this.lxphone
                }
                if (!(/^1[3456789]\d{9}$/.test(phone))) {
                    if(num==1){
                        this.zphone=''
                    }else{
                        this.lxphone=''
                    }
                    this.$message.error('手机号不正确');
                }
            },
            altertext:function(num){
                this.personalIdentity3Key=''
                if(this.pass){
                    this.pass=false
                    if(num==1){
                        this.$message.error('姓名已修改请重新获取验证码')
                    }else{
                        this.$message.error('身份证号已修改请重新获取验证码')
                    }
                }
            },
            idif:function(){
                if(!this.idNoFormatCheck(this.zid)){
                    this.$message.error('身份证号不正确');
                    this.zid=''
                }
            },
            checkEmail:function(){
                var emali=this.email
                var re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/; 
                if (!re.test(emali)) {
                    this.email=''
                    this.$message.error('邮箱不正确');
                } 
            },
            GetQueryString:function(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
                var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
                var context = "";
                if (r != null)
                context = r[2];
                reg = null;
                r = null;
                return context == null || context == "" || context == "undefined" ? "" : context;
            },
            idNoFormatCheck: function (value) {//验证信息
                let num = value.toUpperCase();
                // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
                let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
                if (!reg.test(num)) {
                    return false;
                }
                // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                // 下面分别分析出生日期和校验位
                let len, re;
                len = num.length;
                if (len == 15) {
                    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
                    let arrSplit = num.match(re);
                    // 检查生日日期是否正确
                    let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
                    let bGoodDay;
                    bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                    if (!bGoodDay) {
                    return false;
                    }
                }
                if (len == 18) {
                    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
                    let arrSplit = num.match(re);
                    // 检查生日日期是否正确
                    let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
                    let bGoodDay;
                    bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                    if (!bGoodDay) {
                    return false;
                    } else {
                    // 检验18位身份证的校验码是否正确。
                    // 校验位按照ISO 7064:1983.MOD
                    // 11-2的规定生成，X可以认为是数字10。
                    let valnum;
                    let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                    let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                    let nTemp = 0;
                    for (let i = 0; i < 17; i++) {
                        nTemp += num.substr(i, 1) * arrInt[i];
                    }
                    valnum = arrCh[nTemp % 11];
                    if (valnum != num.substr(17, 1)) {
                        return false;
                    }
                    }
                }
                return true;
            },
        },
    }
</script>
<style lang="scss">
    .up .el-upload-dragger{
        width: 190px;
        height: 144px;
        .el-icon-upload{
            font-size: 50px;
            margin: 30px 0 10px;
            line-height: 35px;
        }
        .el-upload__text{
            padding:0 10px;
            background: rgba(255,255,255,0.5);
        }
    }
    .up .imagtt{
        width: 190px;
        height: 144px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        &>div{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            }
        }
        i.el-icon-circle-close {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 30px;
            display: block;
            cursor: pointer;
            color:#eb3636;
        }
        .el-image-viewer__close i.el-icon-circle-close{
            border-radius: 50%;
            color: #fff;
            font-size: 45px;
            box-shadow: 0px 3px 6px rgb(0 0 0);
        }
    }
    .impower .el-upload-dragger{
        background: url(../../image/signet/business.png) no-repeat center center;
        // background-size: 100% 100%;
    }
    .business .el-upload-dragger{
        background: url(../../image/signet/impower.png) no-repeat center center;
        // background-size: 100% 100%;
    }
    .picture .el-upload-dragger{
        background: url(../../image/signet/business.png) no-repeat center center;
        // background-size: 100% 100%;
    }
    .dialogshil .el-dialog__header{
       background: #4C85E7;
       span{
           color:#fff;
       }
       .el-icon-close{
           color:#fff;
       }
    }
    .hintbox{
        border:0;
        width: 430px;
        .el-message-box__header{
            background: #4C85E7;
            color:#fff;
            &>div{
                top:-10px
            }
            span{
                color:#fff;
            }
            .el-icon-close{
                color:#fff;
            }
        }        
        .conbox{
            margin-top:15px;
            font-size: 16px;
            line-height: 1.2;
            i{
                display: block;
                font-size: 60px;
                color: #0066FF;
            }
            p+p{
                margin-top: 10px;
                font-size: 13px;
                span{
                    color:#2970EA
                }
            }
        }
        .el-message-box__btns button,button:hover{
            background:#5C8CFF;
        }
    }

</style>
<style scoped lang="scss">
    .shilbox{
        text-align: center;
        p{
            margin:0;
            text-align: left;
        }
        img{
            margin-top:20px;
            max-width: 100%;
        }
    }
    
    .ident{
        background: #fff;
        text-align: left;        
        font-size: 12px;        
        font-weight: 400;
        max-width: 1000px;
        margin:0 auto;
        .toptit{
            color: #666666;
            padding:25px 15px;
            border-bottom: 1px solid rgb(238, 240, 242);
            p{
                display: flex;
                align-items: center;
                justify-content:flex-start;
                margin: 0;
                min-width: 60px;
            }
            span{
                font-size: 14px;
                font-weight: bold;
                color: #333333; 
                display: inline-block;
                margin: 0 10px;
            }
            p::before{
                content: "";
                width: 3px;
                height: 18px;
                background: #678DF7;
                border-radius: 2px;
                display: inline-block;               
            }
            
        }
        .message{
            padding:30px;            
            .flow{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 50px;
                div{
                    img{
                        width:65px;
                    }
                    p{
                        color: #333333;
                        margin: 3px 0 0;
                    }
                }
                span{
                    flex:1;                    
                    height: 6px;
                    background: #C6C6C6;
                    border-radius: 3px;
                    margin-bottom: 18px;
                }
                span.activate{
                    background: #678DF7;
                }
            }
            .tab{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #C6C6C6;
                p{                    
                    background: #FFFFFF;
                    border: 1px solid #C6C6C6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin:0 7px 0 0;
                    border-radius:5px 5px 0 0;
                    padding: 7px 10px;
                    border-bottom: 0;
                    cursor: pointer;
                }
                p.activate{
                    background:#678DF7;
                    color: #fff;
                }
            }
            .box{
                .tit{
                    background: #F1F4F5; 
                    color: #333333;
                    padding:8px 10px;
                    margin:20px 0 30px;
                    font-weight: bold;
                }
                .tit.bto{
                    background: #fff;
                    padding-top:35px;
                    border-top:1px solid #F1F4F5;
                }
                .item{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;
                    label{
                        width: 140px;
                        text-align: right;
                        color: #666666;
                        margin-right:10px;

                        span{                            
                            color: #f11001;
                        }
                    }
                    &>div{
                        flex:1;
                        .up{
                           display: flex;
                           justify-content: flex-start;
                           align-items: center;
                        //    .el-upload-dragger{
                        //        background: url(../../image/signet/impower.png);
                        //    }
                        //    .business{
                        //        background: url(../../image/signet/business.png);
                        //    }
                        .el-icon-upload{
                            color:#678DF7;
                        }
                           .right{
                               padding-left: 15px;
                               max-width: 400px;
                                p{
                                    color: #678DF7;
                                    cursor: pointer;
                                    span{
                                        display: inline-block;
                                    }
                                    a{
                                        display: inline-block;
                                        padding:5px;
                                        color: #f11001;
                                        text-decoration: none;
                                        margin-left: 10px;
                                    }
                                }
                                p+p{
                                    color: #999;
                                    cursor: default;
                                }
                           }
                            
                            
                        }
                        p{
                            color:#999;
                           .el-icon-warning{
                                color:red;
                                margin-right: 3px;
                            }
                        }
                        input{
                            padding: 8px;
                            border: 1px solid #EAEAEA;
                            font-size: 14px;
                            color: #333;
                            width: 350px;
                        }
                        input:focus{
                            outline: 0;
                        }
                    }
                    .phtis{
                        display: flex;
                        align-items: center;
                        p{
                            margin: 0 0 0 15px;
                        }
                    }
                    .verify{
                        display: flex;
                        // align-items: center;
                        max-width: 368px;
                        input{
                            flex: 1;
                        }
                        p{
                            width: 85px;
                            background: #678DF7;  
                            color:#fff;  
                            margin:0;
                            padding:5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                }
                .item.tis{
                    label,p{                        
                        color: #DD8C51;
                        margin:0;
                    }
                }
                .btnbox{
                    align-items: center;
                    margin:40px auto 20px;
                    text-align: center;
                    button{
                        background: #4A76EF;
                    }
                }
            }
        }
        
    }
    @media screen and (max-width:991px){
        .ident .message .box .item > div .up .right p span,.ident .message .box .item > div .up .right p+p{
            display: none;
        }
        .ident .message .box .item .phtis{
            flex-wrap: wrap;
            p{
                margin:5px 0 0;
                width: 100%;
            }
        }
    }
    @media screen and (max-width:575px){
        .ident .message .box .item label{
            width: 100px;
        }
        .ident .message .box .item > div input{
            width: 100%;
            box-sizing: border-box
        }
        .ident .message .box .item > div .up{
            flex-wrap: wrap;
            .right{
                width: 100%;
                padding: 0;
                p{
                    margin:0;
                    a{
                        margin:0;
                    }
                }
            }
        }
        
    }
</style>