import { render, staticRenderFns } from "./ident.vue?vue&type=template&id=0cb48916&scoped=true"
import script from "./ident.vue?vue&type=script&lang=js"
export * from "./ident.vue?vue&type=script&lang=js"
import style0 from "./ident.vue?vue&type=style&index=0&id=0cb48916&prod&lang=scss"
import style1 from "./ident.vue?vue&type=style&index=1&id=0cb48916&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb48916",
  null
  
)

export default component.exports